import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import { useDeps } from '@Contexts/DI';
import styles from './ListingGroupLinks.module.css';

import type { GroupCategoryData, GroupData } from '@Components/Filters/typings';
import type { FC, MouseEvent } from 'react';
import type { FilterView } from '@Types/Filters';

export interface ListingGroupLinksProps {
  className?: string;
  group: GroupData;
  filterView?: FilterView;
  onClose?: (e: MouseEvent) => void;
}

const ListingGroupLinks: FC<ListingGroupLinksProps> = (props) => {
  const { className, group, filterView, onClose, ...restProps } = props;
  const { analytics } = useDeps();
  const isViewSticky = filterView === 'sticky';

  const handleClick = useCallback(
    (e: MouseEvent, category: GroupCategoryData) => {
      if (category.disabled) return;

      analytics.dispatchEvent('catalog.grouping.accept', {
        groupName: category.name,
        status: isViewSticky ? 'закреп' : 'не закреп',
      });

      if (onClose) onClose(e);
    },
    [analytics, isViewSticky, onClose],
  );

  return (
    <div {...restProps} className={cn(styles.group, className)}>
      <div className={styles.groupName}>{group.groupName}</div>

      <div className={styles.categories}>
        {group.categories.map((category) => (
          <Link
            className={cn(styles.category, 'TagLink')}
            to={category.translite}
            key={`${category.name} ${category.translite}`}
            preventDefault={category.disabled}
            onClick={(e) => {
              handleClick(e, category);
            }}
          >
            {category.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(ListingGroupLinks);
