import { useCallback, memo, useRef, useState } from 'react';
import { cn, Scroller, Cross } from '@divlab/divanui';

import ModalMain from '@Modals/_components/ModalMain';
import ListingGroupLinks from '@Components/ListingGroupLinks';
import useMedias from '@Hooks/useMedias';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import styles from './MobileGroupsModal.module.css';

import type { FC, MouseEvent } from 'react';
import type { GroupData } from '@Components/Filters/typings';
import type { ModalMainProps } from '@Modals/_components/ModalMain';

export interface MobileGroupsModalData {
  label?: string;
  groups: GroupData[];
  onClose?: (e: MouseEvent) => void;
}

export type MobileGroupsModalProps = ModalMainProps<MobileGroupsModalData>;

const MobileGroupsModal: FC<MobileGroupsModalProps> = (props) => {
  const { modal, onClose, ...restProps } = props;
  const { label, groups } = modal.data;
  const refGroups = useRef<HTMLDivElement>(null);
  const [needScroll, setNeedScroll] = useState(false);
  const { isMobile } = useMedias();

  const handleClose = useCallback(
    (e) => {
      if (!modal.visible) return;
      if (modal.data.onClose) {
        modal.data.onClose(e);
      }

      onClose(e);
    },
    [modal.data, modal.visible, onClose],
  );

  const refContent = useCallback((content: HTMLDivElement) => {
    if (content !== null && refGroups.current) {
      if (
        content.clientHeight < refGroups.current.scrollHeight ||
        content.clientWidth < refGroups.current.scrollWidth
      ) {
        setNeedScroll(true);
      }
    }
  }, []);

  const refModalClose = useOnClickOutside<HTMLDivElement>(handleClose);

  const gridConfig = cn({
    [styles.column1]: groups.length === 1 || isMobile,
    [styles.column2]: groups.length === 2,
    [styles.column3]: groups.length === 3,
    [styles.column4]: groups.length >= 4 && !isMobile,
  });

  return (
    <ModalMain {...restProps} fullscreen modal={modal} onClose={handleClose}>
      <div className={cn(styles.content, { [styles.needScroll]: needScroll })} ref={refContent}>
        <div className={styles.contentInner} ref={refModalClose}>
          <div className={styles.containerLabel} onClick={handleClose}>
            <span className={styles.label}>{label}</span>
            <Cross size='m' />
          </div>

          {needScroll ? (
            <div className={styles.groups}>
              <Scroller className={styles.scroller}>
                <div className={gridConfig}>
                  {groups.map((group) => (
                    <ListingGroupLinks
                      className={styles.group}
                      key={group.groupName}
                      group={group}
                      onClose={handleClose}
                    />
                  ))}
                </div>
              </Scroller>
            </div>
          ) : (
            <div className={cn(styles.groups, gridConfig)} ref={refGroups}>
              {groups.map((group) => (
                <ListingGroupLinks
                  className={styles.group}
                  key={group.groupName}
                  group={group}
                  onClose={handleClose}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </ModalMain>
  );
};

export default memo(MobileGroupsModal);
